import React, { useEffect } from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { StaticRouter } from "react-router-dom/server";

import './css/mobile.css'
import '../public/fontawesome/css/all.css'

import 'react-phone-number-input/style.css'












const Render = ({ url }) => {

  useEffect(() => {
    // Load css files that should only be rendered on client. These are located in the public folder
    if (typeof window !== 'undefined') {
      loadCSS('/css/vivify.min.css');
    }
  }, []);

  if (typeof window == 'undefined') {
    return (
      <MantineProvider withGlobalStyles defaultColorScheme="auto" withNormalizeCSS theme={{
        colors: {
          brand: ['#F0BBDD', '#a6a6a6', '#626262', '#626262', '#454545', '#1f1f1f', '#044EB3', '#044EB3', '#1e1d1e', 'rgb(23, 23, 23)'],
        },
        primaryColor: 'brand',
      }}>
        <StaticRouter location={url}>
          <Notifications />
          <App />
        </StaticRouter>
      </MantineProvider>
    )
  }
  else {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS theme={{
        colors: {
          brand: ['#F0BBDD', '#a6a6a6', '#626262', '#626262', '#454545', '#1f1f1f', '#044EB3', '#044EB3', '#1e1d1e', 'rgb(23, 23, 23)'],
        },
        primaryColor: 'brand',
      }}>

        <BrowserRouter>
          <Notifications />
          <App />
        </BrowserRouter>
      </MantineProvider>
    )
  }



}


function loadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
}


export default Render