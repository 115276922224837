import { useSearchParams } from 'react-router-dom';
import { useObjectSignal } from '../../library/client/objectSignal';
import { useEffect } from 'react';

function UpdateParam() {
  const [searchParams, setSearchParams] = useSearchParams();

    const updateParams = (details) => {
            const newParams = new URLSearchParams(searchParams);
            for(const key in details) {
                newParams.set(key, details[key]);
            }
            setSearchParams(newParams);    
    }

    useEffect(() => {
        const handleCustomEvent = (event) => {
          updateParams(event.detail);
        };
    
        window.addEventListener('updateParams', handleCustomEvent);
    
        return () => {
          window.removeEventListener('updateParams', handleCustomEvent);
        };
      }, []);

  return (<></>
  );
}

export default UpdateParam;
