import _ from 'lodash';
import React from 'react';

/**
 * Manages global object signals with path-based access and immutable updates
 */
class ObjectSignalStore {
  constructor() {
    /** Internal store for signal values */
    this._store = {};
  }

  /**
   * Sets an initial value for a given path if not already set
   * @param {string} path - Dot-separated path to the signal
   * @param {*} initialValue - Initial value to set
   * @returns {*} The set or existing value
   */
  set(path, initialValue) {
    if (!_.has(this._store, path)) {
      _.set(this._store, path, initialValue);
    }
    return this.get(path);
  }

  /**
   * Retrieves value at a given path
   * @param {string} path - Dot-separated path to the signal
   * @param {*} [defaultValue] - Fallback value if path doesn't exist
   * @returns {*} The value at the specified path
   */
  get(path, defaultValue) {
    return _.get(this._store, path, defaultValue);
  }

  /**
   * Updates value at a given path, supporting functional updates
   * @param {string} path - Dot-separated path to the signal
   * @param {*|function} valueOrUpdater - New value or update function
   * @returns {*} The new value
   */
  update(path, valueOrUpdater) {
    const currentValue = this.get(path);
    const newValue = typeof valueOrUpdater === 'function' 
      ? valueOrUpdater(currentValue)
      : valueOrUpdater;
    
    _.set(this._store, path, _.cloneDeep(newValue));
    return newValue;
  }
}

/** Global signal store instance */
export const globalSignal = new ObjectSignalStore();

/**
 * React hook for creating and managing object signals
 * @param {string} path - Dot-separated path to store the signal
 * @param {*} initialValue - Initial value for the signal
 * @returns {[function, function]} Tuple of getter and setter functions
 */
export const useObjectSignal = (path, initialValue) => {
  const [value, setValue] = React.useState(() => {
    return globalSignal.set(path, initialValue);
  });

  const updateValue = React.useCallback((valueOrUpdater) => {
    const newValue = globalSignal.update(path, valueOrUpdater);
    setValue(newValue);
  }, [path]);

  const getValue = React.useCallback(() => globalSignal.get(path, initialValue), [path]);

  return [getValue, updateValue];
};